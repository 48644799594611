$blue-color: #272362;
$yellow-color: #ffad02;
$rose-color: #831818;
$light-yellow-color: #dab469;

h1 {
    font-weight: $font-weight-black;
}

.page {
    &::before {
        content: "Không hỗ trợ trên Desktop \a Vui lòng truy cập từ thiết bị di động";
        white-space: pre;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2vw;
        z-index: 1000;
        color: #fff;
        font-weight: $font-weight-black;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        text-align: center;
    }

    *.mt-0 {
        margin-top: 0 !important;
    }

    .wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        min-height: 100vh;

        .main-bg {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            pointer-events: none;
        }

        .el-wrap {
            position: relative;
            z-index: 2;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;

            &.top {
                margin-bottom: auto;
                padding: 30px 20px 0;
                text-align: center;
            }

            &.bottom {
                position: fixed;
                bottom: 80px;
                margin-top: auto;
                padding: 0 40px;
                max-width: 450px;
                left: 50%;
                transform: translateX(-50%);
                // padding: 0 40px 80px;

                &.text-center {
                    text-align: center;
                }

                .btn {
                    + .btn {
                        margin-top: 15px;
                    }
                }
            }
        }

        .img-kv {
            height: 42px;
            width: auto;
            object-fit: cover;
        }

        .logo-wrap {
            display: flex;
            align-items: center;

            img {
                width: auto;
                object-fit: contain;
            }

            .logo-marathon {
                margin-right: auto;

                img {
                    height: 34px;
                }
            }

            .logo-30years {
                margin-left: auto;
                height: 36px;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        &::before {
            display: none;
        }

        .wrapper {
            .el-wrap {
                &.bottom {
                    // padding: 0 15vw 160px;
                    padding: 0 15vw;
                }
            }
        }
    }
}

footer {
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100vw;
    padding: 0 20px;
    font-size: 9px;
    z-index: 100;
    text-align: center;
    font-weight: $font-weight-normal;
}

// 42 34 244 346
@keyframes flicker {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}

.btn {
    position: relative;
    display: inline-block;
    border-radius: 100px;
    padding: 6px;
    min-width: 240px;
    font-size: 20px;
    letter-spacing: -0.375px;
    font-weight: $font-weight-black;
    max-width: 100%;
    cursor: pointer;
    transform: all ease-in-out 0.25s;

    &::before {
        content: "";
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 100px;
        z-index: -1;
    }

    span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 30px;
        height: 30px;
        border-radius: 100px;
        box-shadow: inset 5px 15px 15px rgba(0, 0, 0, 0.1);
        text-shadow:
            0px 1px 5px rgba(136, 13, 13, 0.2),
            0px 3px 4px rgba(123, 12, 12, 0.12),
            0px 2px 4px rgba(91, 26, 26, 0.14);
        z-index: 1;
        overflow: hidden;
        max-width: 100%;
        transition: all ease-in-out 0.25s;

        img {
            height: 21px;
        }

        strong {
            &.is-flicker {
                animation: flicker 0.8s infinite alternate;
            }
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
    }

    &.btn-blue {
        background-image: linear-gradient(to bottom, #3f37a3, #252160 55%, #050241 75%, #01002b);

        span {
            background-color: #282464;
            background-image: linear-gradient(to bottom, #1e1a59, #282464 30%);
            svg {
                margin-right: 5px;
            }
        }

        &:hover {
            span {
                color: $yellow-color;
            }
        }
    }

    &.btn-red {
        background-image: linear-gradient(to bottom, #fd3543, #ed1922 55%, #e80000 75%, #e50001);

        span {
            background-color: #282464;
            background-image: linear-gradient(to bottom, #ee1d24, #ee1d24 30%);

            svg {
                margin-right: 5px;
            }
        }

        &:hover {
            span {
                color: $yellow-color;
            }
        }
    }

    &.btn-rose {
        background-image: linear-gradient(to bottom, #500000, #780e0e 55%, #b32928 75%, #c22c2e);

        span {
            background-color: #282464;
            background-image: linear-gradient(to bottom, $rose-color, $rose-color 30%);
            box-shadow: inset 5px -15px 15px rgba(0, 0, 0, 0.1);

            svg {
                margin-right: 5px;
            }
        }

        &:hover {
            span {
                color: $yellow-color;
            }
        }
    }

    &.btn-yellow {
        background-image: linear-gradient(to bottom, #f7e193, #d9b264 55%, #ce9b3e 75%, #ca9331);

        span {
            background-color: #282464;
            background-image: linear-gradient(to bottom, #d7b060, #dcb66c 30%);

            svg {
                margin-right: 5px;
            }
        }

        &:hover {
            filter: saturate(3);
            // span {
            //     color: #111;
            // }
        }
    }
}

.gradient-box {
    padding: 10px;
    background-image: linear-gradient(
        to left,
        rgba($rose-color, 0),
        rgba($rose-color, 0.7) 20%,
        rgba($rose-color, 0.9),
        rgba($rose-color, 0.7) 80%,
        rgba($rose-color, 0)
    );

    .users-in-game {
        font-size: 20px;
        white-space: nowrap;

        strong {
            font-size: 25px;
            color: $yellow-color;
        }
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s;

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(#000, 0.3);
        z-index: 2;
    }

    &.active {
        opacity: 1;
        pointer-events: auto;
        -webkit-backdrop-filter: saturate(180%) blur(4px);
        backdrop-filter: saturate(180%) blur(4px);
    }

    .container {
        z-index: 3;
        .box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            width: 100%;
            padding: 0 20px;
        }

        .content {
            position: relative;
            width: 100%;
            padding: 20px;
            border-radius: 20px;
            background-color: #dab469;
            border: solid 5px #f4df9c;
            color: #111;
            text-align: center;

            .close-btn {
                position: absolute;
                right: -10px;
                bottom: calc(100% + 10px);
                font-size: 11px;
                color: red;
                cursor: pointer;
            }

            .btn {
                &::before {
                    z-index: initial;
                }
            }
        }
    }
}

.home {
    h1 {
        font-size: 42px;
        margin-top: 24px;
        text-shadow: 0px 1px 5px rgba(136, 13, 13, 0.20), 0px 3px 4px rgba(123, 12, 12, 0.12), 0px 2px 4px rgba(91, 26, 26, 0.14);
    }

    .gradient-box {
        margin-top: 30px;
    }
}

.login {
    .login-wrap {
        padding-bottom: 20px !important;

        .btn-yellow {
            + p {
                margin-top: 40px;
            }
        }
    }
}

.register {
    .el-wrap {
        &.bottom {
            // padding: 0 30px !important;
            // margin-bottom: 80px !important;
            padding-left: 30px !important;
            padding-right: 30px !important;
        }
    }

    h1 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .register-wrap {
        border-radius: 27px;
        background-color: rgba($color: $rose-color, $alpha: 0.9);
        padding: 20px;
        max-height: calc(100vh - 355px);
        overflow-x: hidden;
        overflow-y: scroll;

        p {
            margin: 0 0 10px;
        }

        .btn {
            width: 100%;
            margin-bottom: 20px;

            span {
                line-height: 40px;
                height: 40px;
            }
        }

        .note {
            margin-top: 20px;
        }
    }

    .character-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .box {
            position: relative;
            width: calc(50% - 10px);
            cursor: pointer;

            &::before {
                content: "";
                position: absolute;
                top: -4px;
                right: -4px;
                bottom: -4px;
                left: -4px;
                border-radius: 24px;
                background-image: linear-gradient(to bottom, rgba(#c99330, 0), rgba(#f8e6a4, 0));
                pointer-events: none;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }

            + .box {
                margin-left: 20px;
            }

            &.active {
                &::before {
                    background-image: linear-gradient(to bottom, rgba(#c99330, 1), rgba(#f8e6a4, 1));
                }

                .bg-box {
                    background-color: $light-yellow-color;
                }
            }

            .bg-box {
                position: relative;
                background-color: rgba($light-yellow-color, 0);
                border-radius: 24px;
                padding: 30px 30px 15px;
                z-index: 2;
            }

            img {
                width: 100%;
                height: 10vh;
                object-fit: contain;
                transition: all 0.5s;
            }
        }
    }

    .btn-register {
        margin-top: 15px !important;

        img {
            margin-left: 10px;
            height: 24px;
        }

        span {
            height: 40px;
            line-height: 40px;
        }
    }
}

.scrollable-content {
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }
}

.popup {
    &.ranking,
    &.rules {
        .box {
            .content {
                padding: 45px 15px 15px;

                .close-btn {
                    bottom: calc(100% + 25px);
                }
            }
        }

        .btn-title {
            position: absolute;
            bottom: calc(100% - 20px);
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 40px);
            color: #fff;

            span {
                img {
                    &:nth-of-type(2) {
                        margin-left: -5px;
                    }
                }

                strong {
                    margin-left: 5px;
                }
            }
        }
    }

    &.ranking {
        .users-list {
            position: relative;
            // margin-top: 20px;
            border-radius: 20px;
            overflow: hidden;

            &::after {
                content: "";
                position: absolute;
                top: 5px;
                right: 5px;
                bottom: 5px;
                left: 5px;
                border-radius: 20px;
                z-index: 10;
                box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
                pointer-events: none;
            }

            ul {
                box-shadow: 0 0 10px rgba(#000, 0.3);
                max-height: 55vh;
                overflow-x: hidden;
                overflow-y: scroll;
                font-weight: $font-weight-black;

                li {
                    display: flex;
                    align-items: center;
                    padding: 15px 20px;

                    * {
                        color: #fff;
                    }

                    &:nth-child(2n + 1) {
                        background-color: #f7b025;
                    }

                    &:nth-child(2n) {
                        background-color: #ffcf71;
                    }

                    img {
                        height: 34px;
                        margin-right: 10px;
                    }

                    span {
                        line-height: 34px;
                    }

                    .user-order {
                        position: relative;
                        line-height: initial;
                        margin-right: 10px;

                        strong {
                            display: inline-block;
                            position: relative;
                            z-index: 3;
                            width: 28px;
                            line-height: 28px;
                            background-color: #f7931e;
                            border-radius: 100px;
                            font-size: 14px;
                        }

                        &::before,
                        &::after {
                            content: "";
                            position: absolute;
                            border-radius: 100px;
                        }

                        &::before {
                            top: -2px;
                            right: -2px;
                            bottom: -2px;
                            left: -2px;
                            background-color: #fbcc34;
                            z-index: 2;
                        }

                        &::after {
                            top: -3px;
                            right: -3px;
                            bottom: -3px;
                            left: -3px;
                            z-index: 1;
                            background-color: #f4e09c;
                        }
                    }

                    .user-name {
                        font-size: 18px;
                        max-width: 60%;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;  
                        overflow: hidden;
                        text-align: left;
                        line-height: 1.5;
                    }

                    .user-point {
                        margin-left: auto;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    &.rules {
        .rule-content {
            padding: 10px 20px;
            background-color: #fff;
            border-radius: 20px;
            max-height: 60vh;
            overflow-x: hidden;
            overflow-y: scroll;
            text-align: left;

            ul {
                list-style: number;
                padding: 0 20px;
            }
        }
    }
}
