$font-mont-al: "Montserrat Alternates", sans-serif;
$font-weight-black: 900;
$font-weight-bold: 600;
$font-weight-normal: 400;

// @import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,600;0,900;1,400&display=swap");
@import "pages.scss";

body {
  margin: 0;
  font-family: $font-mont-al;
  font-weight: $font-weight-normal;
  font-style: normal;
  line-height: 1.4;
  color: #ffffff;
  // background: #ffffff url(./assets/img/img_bg_main.jpg) center no-repeat;
  // background-size: auto 105%;

  * {
    box-sizing: border-box;
  }
  div,
  image,
  a,
  input,
  select {
    // font-family: "Snowflake Sans";
    font-weight: 400;
    font-style: normal;
    // transition: all ease 0.35s;
  }
  strong,
  b {
    font-weight: 700;
  }
  img {
    max-width: 100%;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    font-weight: bold;
    text-decoration: none;
    color: #fffae3;
    transition: all ease 0.35s;

    // &:hover {
    //   opacity: 0.7;
    // }
  }
}

#game-wrapper {
  max-width: 450px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);

  > canvas {
    width: 100%;
  }
}

.container {
  // padding: 0 20px;
  position: relative;

  h1 {
    // margin-top: 0;
  }

  @media screen and (min-width: 450px) {
    max-width: 450px;
    margin: 0 auto;
    padding: 0 20px;
  }
}

.general-page {
  &.home-page {
    .container-wrapper {
      display: flex;
      flex-direction: column;
      .img-kv {
        padding: 3vh 0 2vh;
        text-align: center;
        > img {
          width: 100%;
          max-width: calc(100% - 40px);
        }
      }

      h1 {
        margin-bottom: 10px;
        text-align: center;
        text-shadow:
          0px 1px 5px rgba(136, 13, 13, 0.2),
          0px 3px 4px rgba(123, 12, 12, 0.12),
          0px 2px 4px rgba(91, 26, 26, 0.14);
        font-size: 40px;
        font-weight: 900;
        line-height: 1.5;
        letter-spacing: -0.6px;
      }

      .action-box {
        margin-top: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        .info-label {
          margin-top: 10px;
        }
      }
    }
  }
}

// footer {
//   font-size: 11px;
//   text-align: center;
//   letter-spacing: -0.1px;
//   padding: 15px 0 15px;
// }

.btn-primary {
  font-family: $font-mont-al, sans-serif;
  font-weight: 900;
  position: relative;
  appearance: none;
  background: none;
  border: 0;
  color: #fff;
  display: block;
  padding: 0;
  cursor: pointer;
  transition: all ease-in 0.2s;
  ~ .btn-primary {
    margin-top: -5px;
  }
  > img {
    display: block;
  }

  &:hover {
    opacity: 1;
    color: #ffae02;
  }

  &.btn-yellow {
    &:hover {
      // color: #272362;
      filter: saturate(3);
    }
  }

  .btn-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -3px;
    > span {
      font-size: 20px;
      font-weight: bold;
      text-shadow:
        0px 1px 5px rgba(136, 13, 13, 0.2),
        0px 3px 4px rgba(123, 12, 12, 0.12),
        0px 2px 4px rgba(91, 26, 26, 0.14);
      ~ span {
        margin-left: 6px;
      }
    }

    .kv {
      max-width: 158px;
    }
  }
}

.info-label {
  display: block;
  padding: 1px 25px;
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: -0.1px;
  margin-bottom: 5px;

  strong {
    font-size: 25px;
    color: #ffad02;
  }

  background: linear-gradient(90deg, transparent 2%, #831818 20%, #831818 80%, transparent 98%);
}
