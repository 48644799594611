// .game-popup {
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     top: 0;
//     left: 0;
//     background: #ffae02 url(assets/img/img_bg_finish_game.jpg) center no-repeat;
//     background-size: auto 100%;
//     padding: 0 20px;
// }

.page {
    &.page-pregame,
    &.page-endgame {
        .character {
            margin-top: -70px;
            padding-bottom: 20px;
            .mascot {
                img {
                    max-width: 100px;
                }
            }
            .name {
                font-size: 20px;
                font-weight: 900;
            }
        }
    }
    &.page-pregame {
        .el-wrap {
            &.top {
                margin-bottom: 0;
            }
            &.bottom {
                margin-top: 0;
                @media screen and (max-width: 1024px) {
                    // padding: 0 20px 125px;
                }
                .pregame-wrap {
                    max-height: calc(100vh - 200px);
                    overflow-x: hidden;
                    overflow-y: scroll;
                }
            }
        }

        text-shadow:
            0px 1px 5px rgba(136, 13, 13, 0.2),
            0px 3px 4px rgba(123, 12, 12, 0.12),
            0px 2px 4px rgba(91, 26, 26, 0.14);

        .img-goal {
            margin-top: 15px;
        }

        .guide {
            p {
                font-size: 20px;
                font-weight: 900;
                margin-top: 0;
            }

            @media screen and (max-width: 425px) {
                display: flex;
                justify-content: center;
                > div {
                    width: 50%;
                    padding: 0 10px 10px;
                }
                img {
                    height: 35px;
                }
                p {
                    font-size: 16px;
                    br {
                        display: none;
                    }
                }
            }
        }
    }
    &.page-endgame {
        .score-box {
            padding-top: 15px;
            p {
                margin: 0;
                font-size: 20px;
                text-shadow:
                    0px 1px 5px rgba(136, 13, 13, 0.2),
                    0px 3px 4px rgba(123, 12, 12, 0.12),
                    0px 2px 4px rgba(91, 26, 26, 0.14);
                strong {
                    font-size: 90px;
                    line-height: 0.8;
                }
                ~ p {
                    margin-top: 10px;
                }
            }
        }

        .action {
            > div {
                .btn-outline {
                    margin-top: 29px;
                    display: block;
                    font-weight: 900;
                    font-size: 20px;
                    text-shadow:
                        0px 1px 5px rgba(136, 13, 13, 0.2),
                        0px 3px 4px rgba(123, 12, 12, 0.12),
                        0px 2px 4px rgba(91, 26, 26, 0.14);
                }
            }
        }
    }
}

#game-wrapper{
    user-select: none;
}
